import React, {Component} from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import BrandTwo from "../elements/BrandTwo";
import {FaFacebookF, FaLinkedinIn, FaTwitter} from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import {FiChevronUp} from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import about from "../assets/images/about/about-3.jpg";
import findingImg from "../assets/images/about/finding-us-01.png";
import teamImg1 from "../assets/images/team/team-01.jpg";
import teamImg2 from "../assets/images/team/team-02.jpg";
import teamImg3 from "../assets/images/team/team-03.jpg";

class About extends Component {
    render() {
        let title = "About",
            description = " For over a decade, Future Systems has been at the forefront of IT and Networking services, specializing in on-premises data center operations and harnessing the power of cloud-hosted emerging technologies. Our extensive experience and deep industry knowledge enable us to design and implement robust IT infrastructures that meet the dynamic needs of modern businesses. We are committed to excellence and innovation, consistently delivering tailored solutions that ensure operational efficiency and scalability. Our expertise spans a broad spectrum of IT disciplines, ensuring we can handle any challenge and transform technology into a strategic business advantage." + 
                        " At Future Systems, we are passionate about data security and providing ubiquitous application access. We understand the critical importance of protecting your data and ensuring it is accessible whenever and wherever it's needed. Our team employs the latest security protocols and advanced cloud technologies to safeguard your information and ensure seamless access across all platforms. By partnering with us, you gain a dedicated ally who is committed to securing your operational capabilities and optimizing your technological investments, enabling your business to thrive in an increasingly digital world. ";
        return (
            <React.Fragment>
                <PageHelmet pageTitle="About"/>

                <Header
                    headertransparent="header--transparent"
                    colorblack="color--black"
                    logoname="logo.png"
                />
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pb--100 bg_image bg_image--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">About Us</h2>
                                    <p>We're here to help!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img
                                            height="550px"
                                            className="w-100"
                                            src={about}
                                            alt="About Images"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            {/*<h2 className="title">{title}</h2>*/}
                                            <p className="description">{description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp/>
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer/>
            </React.Fragment>
        );
    }
}

export default About;
